import {StreamLanguage} from "@codemirror/language"
import {toml} from "@codemirror/legacy-modes/mode/toml"

import {basicSetup} from "codemirror"
import {EditorView} from "@codemirror/view"
import {EditorState, Compartment} from "@codemirror/state"

import {basicLight} from "cm6-theme-basic-light"
import {basicDark} from "cm6-theme-basic-dark"


export class TomlEditor {
  constructor(dom_root, dark_mode) {
    // So we can change config dynamically
    this.editable = new Compartment;

    let theme;
    if (dark_mode) {
      theme = basicDark;
    } else {
      theme = basicLight;
    }



    this.view = new EditorView({
      extensions: [
        basicSetup,
        StreamLanguage.define(toml),
        this.editable.of(EditorView.editable.of(false)),
        theme
      ],
      parent: dom_root
    });


  }

  setText(text) {
    this.view.dispatch(
      {changes: {
        from: 0,
        to: this.view.state.doc.length,
        insert: text
      }}
    );
  }

  getText() {
    return this.view.state.doc.toString();
  }

  setEditable(mode) {
    this.view.dispatch(
      {effects: [
        this.editable.reconfigure(EditorView.editable.of(mode)),
      ]}
    );
  }
}
